<template>
  <div class="w-full">
    <form-row>
      <asterix-radio-group column>
        <asterix-radio-input
          v-for="feedType in CREATIVE_FEED_TYPES"
          :key="feedType.id"
          v-model="selectedType"
          name="feedType"
          :disabled="isEditing"
          :input-value="feedType.id"
        >
          <template #label>
            <div class="flex flex-col items-start">
              <p class="text-sm">{{ feedType.name }}</p>
              <p class="text-xs text-gray-600">{{ feedType.description }}</p>
            </div>
          </template>
        </asterix-radio-input>
      </asterix-radio-group>
    </form-row>

    <div class="px-8 py-4 bg-gray-200 rounded">
      <form-row v-if="selectedType === CREATIVE_FEED_TYPES.FILE.id" class="lg:flex-col">
        <feed-header-file-input v-model="feedFile" :show-mapping="isEditing" @change="onFileChange" />
      </form-row>

      <template v-else>
        <form-row>
          <sun-label-group text="Frequency">
            <button-group
              v-model="buttonSelected"
              class="bg-white rounded-lg"
              :options="buttonsOptions"
              @change="onFrequencyChange"
            />
          </sun-label-group>
        </form-row>

        <form-row>
          <feed-header-url-input v-model="feedFile" :show-mapping="isEditing" @change="onFileChange" />
        </form-row>
      </template>

      <p v-if="showWarningText" class="w-full mx-2 my-1 text-sm text-orange-400 flex items-center">
        <warning-svg class="h-4 w-4 mr-2" />Remember to map the file to continue and complete de field "link"
      </p>
    </div>
  </div>
</template>

<script>
import AsterixRadioGroup from '@/components/molecules/shared/AsterixRadioGroup';
import AsterixRadioInput from '@/components/atoms/AsterixRadioInput';
import CREATIVE_FEED_TYPES from '@/model/modules/ecommerce/offer/creative/creativeFeed/CreativeFeedTypes';
import FormRow from '@/components/atoms/FormRow/FormRow';
import ButtonGroup from '@/components/molecules/ButtonGroup';
import FeedParserProvider from '@/entities/Core/FeedParserProvider';
import FeedHeaderFileInput from '@/components/organisms/shared/FeedHeaderFileInput';
import FeedHeaderUrlInput from '@/components/organisms/shared/FeedHeaderUrlInput';
import FeedFile from '@/model/shared/FeedFile';

export default {
  name: 'CreativeFeedTypePicker',
  components: {
    FeedHeaderUrlInput,
    FeedHeaderFileInput,
    ButtonGroup,
    FormRow,
    AsterixRadioInput,
    AsterixRadioGroup,
    WarningSvg: () => import('@/components/icons/WarningSvg'),
  },
  props: {
    /** @type FeedParserProvider */
    value: {
      type: Object,
      default: () => new FeedParserProvider(),
    },
    isEditing: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      CREATIVE_FEED_TYPES,
      defaultType: CREATIVE_FEED_TYPES.FILE.id,
      selectedType: this.value?.feedType?.id || this.defaultType,
      source: this.value?.source,
      hourInterval: this.value?.hourInterval,
      buttonSelected: 0,
      buttonsOptions: [
        { id: 'hour', name: 'Hourly', value: 1 },
        { id: 'day', name: 'Daily', value: 24 },
        { id: 'biweekly', name: '15 Days', value: 360 },
        { id: 'month', name: 'Monthly', value: 720 },
      ],
      feedFile: null,
    };
  },
  computed: {
    showWarningText() {
      return !this.feedFile.isDictionaryValid();
    },
    defaultHourInterval() {
      return CREATIVE_FEED_TYPES.FILE.id === this.selectedType ? -1 : this.buttonsOptions[0].value;
    },
  },
  watch: {
    selectedType(newType) {
      if (!newType) {
        this.selectedType = this.defaultType;
      }
      this.hourInterval = this.defaultHourInterval;
      const type = newType || this.defaultType;
      this.feedFile = new FeedFile();
      this.updateCreative(CREATIVE_FEED_TYPES[type]);
      this.buttonSelected = 0;
    },
    value() {
      this.initValues();
    },
  },
  created() {
    this.initValues();
  },
  methods: {
    initValues() {
      this.selectedType = this.value?.feedType?.id || this.defaultType;
      this.source = this.value?.source;
      this.hourInterval = this.value?.hourInterval || this.defaultHourInterval;
      this.buttonSelected = this.buttonsOptions.findIndex(button => button.value === this.hourInterval);
      this.feedFile = new FeedFile(this.value?.source, this.value?.delimiter, this.value?.dictionary);
    },
    onFrequencyChange(frequency) {
      this.hourInterval = frequency.value;
      this.updateCreative(null, frequency.value);
    },
    onFileChange(value) {
      this.updateCreative(null, this.hourInterval, value);
    },
    updateCreative(type = null, hourInterval = null, file = null) {
      type = type || CREATIVE_FEED_TYPES[this.selectedType];
      hourInterval = hourInterval || this.defaultHourInterval;
      file = file || this.feedFile;
      const newCreative = this.value;
      newCreative.feedType = type;
      newCreative.hourInterval = hourInterval;
      newCreative.source = file?.source;
      newCreative.delimiter = file?.delimiter;
      newCreative.dictionary = file?.dictionary;

      this.$emit('input', newCreative);
    },
  },
};
</script>

<style scoped>
::v-deep label {
  @apply items-center;
}
</style>
