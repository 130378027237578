var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex align-middle flex-wrap" },
    [
      _c("save-button", {
        ref: "submitBtn",
        staticClass: "w-full sm:w-auto",
        attrs: { loading: _vm.loading },
        on: {
          click: function ($event) {
            return _vm.$emit("save")
          },
        },
      }),
      _c(
        "sun-button",
        {
          staticClass:
            "w-full text-sm custom-p-1 mt-4 sm:w-auto sm:mt-0 sm:ml-4",
          attrs: { variant: "pill", color: "white" },
          on: {
            click: function ($event) {
              return _vm.$emit("cancel")
            },
          },
        },
        [_vm._v(" Cancel ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }