/**
 * FeedFile
 * @class
 * @public
 */
import CsvDelimiters from '@/model/shared/CsvDelimiters';
import FeedStructure from './FeedStructure';

export default class FeedFile extends FeedStructure {
  /**
   * FeedFile data
   * @constructor
   * @typedef {object} FeedFile
   * @property @param {String|File} source
   * @property @param {Object} delimiter
   * @property @param {Object<String,String>>} dictionary
   */
  constructor(source = null, delimiter = CsvDelimiters.AUTO, dictionary = {}) {
    super(dictionary);
    this.source = source;
    this.delimiter = delimiter;
  }
}
