<template>
  <div class="flex button-group">
    <button
      v-for="(option, $index) in options"
      :key="option.id"
      :disabled="value === $index"
      class="item"
      :class="{
        selected: value === $index,
        disabled: !!option.disabled,
      }"
      @click.prevent.stop="onOptionClick(option, $index)"
    >
      <slot name="option" :option="option">
        {{ option.name }}
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonGroup',
  props: {
    value: { type: Number, default: 0 },
    options: { type: Array, default: () => [] },
  },
  methods: {
    onOptionClick(option, index) {
      if (!option?.disabled) {
        this.$emit('input', index);
        this.$emit('change', option);
      }
    },
  },
};
</script>

<style scoped>
.button-group .item:first-child {
  @apply rounded-tl-md rounded-bl-md;
}
.button-group .item:last-child {
  @apply rounded-tr-md rounded-br-md border-r;
}
.button-group .item.selected {
  @apply bg-gray-700 text-white border-gray-700;
}
.button-group .item.selected:hover {
  @apply bg-gray-900;
}
.button-group .item.selected + .item {
  border-left-color: transparent;
}
.button-group .item:hover {
  @apply bg-gray-200;
}
.button-group .item {
  @apply text-gray-700 border border-gray-400 border-r-0 text-sm px-2 py-1;
}
.button-group .item.disabled:hover,
.button-group .item.disabled {
  @apply cursor-not-allowed bg-gray-400;
}
</style>
