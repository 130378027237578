<template>
  <div>
    <form-row class="mt-4">
      <template #left><line-loader class="h-8" /></template>
      <template #right><line-loader class="h-8" /></template>
    </form-row>
    <form-row class="mt-4">
      <template #left><line-loader class="h-8" /></template>
      <template #right><line-loader class="h-8" /></template>
    </form-row>
    <form-row class="mt-4">
      <template #left><line-loader class="h-8" /></template>
      <template #right><line-loader class="h-8" /></template>
    </form-row>
    <form-row class="mt-4">
      <template #left><line-loader class="h-8" /></template>
      <template #right><line-loader class="h-8" /></template>
    </form-row>
    <form-row class="mt-4">
      <template #left><line-loader class="h-8" /></template>
      <template #right><line-loader class="h-8" /></template>
    </form-row>
  </div>
</template>

<script>
import FormRow from '@/components/atoms/FormRow/FormRow';
import LineLoader from '@/components/atoms/Loaders/LineLoader';

export default {
  name: 'FeedHeaderMapperLoader',
  components: { LineLoader, FormRow },
};
</script>
