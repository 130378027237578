var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.fileHeaders, function (header) {
      return _c("form-row", {
        key: header,
        scopedSlots: _vm._u(
          [
            {
              key: "left",
              fn: function () {
                return [
                  _c("asterix-input", {
                    staticClass: "mt-6 lg:mt-0",
                    attrs: {
                      placeholder: _vm.prettyPlaceholders[header],
                      disabled: "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "right",
              fn: function () {
                return [
                  _c("sun-select", {
                    attrs: {
                      options: _vm.fileHeaderOptions[header],
                      "track-by": "id",
                      label: "name",
                      "color-tag": "gray",
                      placeholder: "Not assigned",
                      disabled: _vm.disabledFileHeaders[header],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onTranslationChanged(
                          _vm.fileHeaderMappings[header]
                        )
                      },
                    },
                    model: {
                      value: _vm.fileHeaderMappings[header],
                      callback: function ($$v) {
                        _vm.$set(_vm.fileHeaderMappings, header, $$v)
                      },
                      expression: "fileHeaderMappings[header]",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }