var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c(
        "form-row",
        [
          _c(
            "asterix-radio-group",
            { attrs: { column: "" } },
            _vm._l(_vm.CREATIVE_FEED_TYPES, function (feedType) {
              return _c("asterix-radio-input", {
                key: feedType.id,
                attrs: {
                  name: "feedType",
                  disabled: _vm.isEditing,
                  "input-value": feedType.id,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex flex-col items-start" },
                            [
                              _c("p", { staticClass: "text-sm" }, [
                                _vm._v(_vm._s(feedType.name)),
                              ]),
                              _c(
                                "p",
                                { staticClass: "text-xs text-gray-600" },
                                [_vm._v(_vm._s(feedType.description))]
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedType,
                  callback: function ($$v) {
                    _vm.selectedType = $$v
                  },
                  expression: "selectedType",
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "px-8 py-4 bg-gray-200 rounded" },
        [
          _vm.selectedType === _vm.CREATIVE_FEED_TYPES.FILE.id
            ? _c(
                "form-row",
                { staticClass: "lg:flex-col" },
                [
                  _c("feed-header-file-input", {
                    attrs: { "show-mapping": _vm.isEditing },
                    on: { change: _vm.onFileChange },
                    model: {
                      value: _vm.feedFile,
                      callback: function ($$v) {
                        _vm.feedFile = $$v
                      },
                      expression: "feedFile",
                    },
                  }),
                ],
                1
              )
            : [
                _c(
                  "form-row",
                  [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Frequency" } },
                      [
                        _c("button-group", {
                          staticClass: "bg-white rounded-lg",
                          attrs: { options: _vm.buttonsOptions },
                          on: { change: _vm.onFrequencyChange },
                          model: {
                            value: _vm.buttonSelected,
                            callback: function ($$v) {
                              _vm.buttonSelected = $$v
                            },
                            expression: "buttonSelected",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "form-row",
                  [
                    _c("feed-header-url-input", {
                      attrs: { "show-mapping": _vm.isEditing },
                      on: { change: _vm.onFileChange },
                      model: {
                        value: _vm.feedFile,
                        callback: function ($$v) {
                          _vm.feedFile = $$v
                        },
                        expression: "feedFile",
                      },
                    }),
                  ],
                  1
                ),
              ],
          _vm.showWarningText
            ? _c(
                "p",
                {
                  staticClass:
                    "w-full mx-2 my-1 text-sm text-orange-400 flex items-center",
                },
                [
                  _c("warning-svg", { staticClass: "h-4 w-4 mr-2" }),
                  _vm._v(
                    'Remember to map the file to continue and complete de field "link" '
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }