<template>
  <asterix-form-modal closable modal-class="asterix-modal-size h-full" @submit="onSubmit" @cancel="closeModal">
    <template #title><slot name="title"></slot></template>
    <template #content>
      <form-row>
        <sun-label-group class="w-full" text="Title">
          <asterix-input
            id="name"
            v-model="feedParser.name"
            type="text"
            name="name"
            validate-on-blur
            :minlength="3"
            :maxlength="50"
            placeholder="My cool feed parser provider..."
            required="required"
          />
        </sun-label-group>
      </form-row>
      <form-row>
        <sun-label-group text="Add Utms" class="w-full">
          <sun-text-area :value="feedParser.utm" resize="resize-y" @change="utmChanges"></sun-text-area>
        </sun-label-group>
      </form-row>

      <form-row>
        <creative-feed-type-picker v-model="feedParser" :is-editing="isEditing" />
      </form-row>
    </template>
    <template #footer>
      <asterix-footer-modal :loading="isLoading" @cancel="closeModal" />
    </template>
  </asterix-form-modal>
</template>

<script>
import { deepClone } from '@/utils/deepClone';
import AsterixFooterModal from '@/components/molecules/shared/AsterixFooterModal';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import AsterixInput from '@/components/atoms/AsterixInput';
import CreativeFeedTypePicker from '@/components/organisms/modules/ecommerce/offer/CreativeFeedTypePicker';
import FeedParserProvider from '@/entities/Core/FeedParserProvider';
import FormRow from '@/components/atoms/FormRow/FormRow';

export default {
  name: 'FeedParserProviderModal',
  components: {
    AsterixFooterModal,
    AsterixFormModal,
    AsterixInput,
    CreativeFeedTypePicker,
    FormRow,
  },
  props: {
    /** @type FeedParserProvider */
    value: {
      type: FeedParserProvider,
      default: () => new FeedParserProvider(),
    },
    isEditing: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    feedParser: null,
  }),
  watch: {
    value: {
      deep: true,
      handler(newCrea) {
        this.feedParser = deepClone(newCrea);
      },
    },
  },
  created() {
    this.feedParser = deepClone(this.value);
  },
  methods: {
    closeModal() {
      this.$emit('cancel');
    },
    onSubmit(evt) {
      if (!this.feedParser.isDictionaryValid()) return;

      this.$emit('input', this.feedParser);
      this.$emit('submit', evt);
    },
    utmChanges(event) {
      this.feedParser.utm = event.value;
    },
  },
};
</script>
