var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex button-group" },
    _vm._l(_vm.options, function (option, $index) {
      return _c(
        "button",
        {
          key: option.id,
          staticClass: "item",
          class: {
            selected: _vm.value === $index,
            disabled: !!option.disabled,
          },
          attrs: { disabled: _vm.value === $index },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.onOptionClick(option, $index)
            },
          },
        },
        [
          _vm._t(
            "option",
            function () {
              return [_vm._v(" " + _vm._s(option.name) + " ")]
            },
            { option: option }
          ),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }