<template>
  <div>
    <form-row v-for="header in fileHeaders" :key="header">
      <template #left>
        <asterix-input :placeholder="prettyPlaceholders[header]" disabled class="mt-6 lg:mt-0" />
      </template>
      <template #right>
        <sun-select
          v-model="fileHeaderMappings[header]"
          :options="fileHeaderOptions[header]"
          track-by="id"
          label="name"
          color-tag="gray"
          placeholder="Not assigned"
          :disabled="disabledFileHeaders[header]"
          @change="onTranslationChanged(fileHeaderMappings[header])"
        />
      </template>
    </form-row>
  </div>
</template>

<script>
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import { deepClone } from '@/utils/deepClone';

export default {
  name: 'FeedHeaderMapper',
  components: { AsterixInput, FormRow },
  props: {
    /** @type {Object}  key is the header from file, value is our header mapping */
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fileHeaders: [],
      fileHeaderMappings: {},
      fileHeaderOptions: {},
      baseAllowedHeaders: [],
    };
  },
  computed: {
    missingHeadersToMap() {
      return this.baseAllowedHeaders.filter(header => !header?.selected).map(header => header.name);
    },
    /**
     * This variable holds the data to display on the left column properly formatted for the user
     * */
    prettyPlaceholders() {
      const placeholders = {};
      this.fileHeaders.forEach(header => {
        placeholders[header] = header.replace('][', ' > ').slice(0, -1).slice(1);
      });
      return placeholders;
    },
    /**
     * This variable has the control of which selects are enabled or not. Selects are disabled when all the options
     * have been mapped
     * */
    disabledFileHeaders() {
      const headers = {};
      this.fileHeaders?.forEach(header => {
        headers[header] = !this.fileHeaderMappings[header] && !this.missingHeadersToMap?.length;
      });
      return headers;
    },
  },
  watch: {
    fileHeaderMappings: {
      deep: true,
      handler: 'onHeaderMappingsChanged',
    },
  },
  created() {
    this.initDictionary(this.value);
  },
  methods: {
    initDictionary(dict) {
      const dictionary = deepClone(dict);
      Object.keys(dictionary.file).forEach(key => (dictionary.file[key] = null));
      this.fileHeaders = Object.keys(dictionary.file);
      this.fileHeaderMappings = dictionary?.feed ? dictionary.feed : {};
      this.baseAllowedHeaders = dictionary.our;

      this.setFileHeaderOptions();
    },
    /**
     * Set the options for each header. This method add the attribute `$isDisabled` to the select options, so when any of the
     * options on the baseAllowedHeaders is set as `selected`, and it is not the current select value, it is set as disabled
     * */
    setFileHeaderOptions() {
      this.baseAllowedHeaders.forEach(header => {
        header.selected = Object.values(this.fileHeaderMappings).find(val => val?.id === header.id);
      });

      const opts = {};
      this.fileHeaders?.forEach(header => {
        const headers = deepClone(this.baseAllowedHeaders);
        const optsUpdated = Object.values(headers);
        optsUpdated.forEach(opt => {
          opt.$isDisabled = !!this.baseAllowedHeaders.find(base => {
            return (
              base.id === opt.id &&
              base?.selected &&
              (!this.fileHeaderMappings[header] || this.fileHeaderMappings[header]?.id !== opt.id)
            );
          });
        });
        opts[header] = optsUpdated;
      });
      this.fileHeaderOptions = opts;
    },
    /**
     * On new translation handler
     *
     * @params {value} the selected option
     * @params {Object} the value of the current selected option
     *
     * We need to take care from the current selected option due to the remove case, where the value is undefined
     * and we do not have any way to check
     * */
    onTranslationChanged(currentOption) {
      if (currentOption) {
        this.baseAllowedHeaders.forEach(header => {
          if (header.id === currentOption.id) {
            this.$set(header, 'selected', false);
          }
        });
      }
    },
    onHeaderMappingsChanged() {
      this.setFileHeaderOptions();
      this.$emit('input', { our: this.value.our, file: this.value.file, feed: this.fileHeaderMappings });
      this.$emit('change');
    },
  },
};
</script>
