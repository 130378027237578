<template>
  <div class="w-full">
    <form-row>
      <div class="flex w-full">
        <sun-label-group text="Feed URL" class="w-full">
          <asterix-input
            v-model.trim="feedFile.source"
            class="w-full"
            class-input="w-full"
            type="url"
            placeholder="https://www.example.com/my-custom-feed/"
            required="required"
            auto-validate
            @change="emitValue"
          />
        </sun-label-group>
        <sun-button
          variant="pill"
          color="gray"
          class="custom-p-1 bg-gray-800 hover:bg-gray-900 text-xs ml-2 h-8 mt-8"
          :disabled="!feedFile.source"
          :loading="mapLoading"
          @click="onMapClick"
          >Map</sun-button
        >
      </div>
    </form-row>

    <form-row v-if="showHeaderMapping">
      <div class="w-full flex-col text-left mt-4">
        <feed-header-mapper v-if="!mapLoading" v-model="feedFile.dictionary" @change="emitValue" />
        <feed-header-mapper-loader v-else />

        <sun-button variant="pill" class="custom-p-1 text-xs mt-4" color="gray" @click="reset">Reset</sun-button>
      </div>
    </form-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import FeedHeaderMapper from '@/components/organisms/shared/FeedHeaderMapper';
import FeedFile from '@/model/shared/FeedFile';
import apiRequest from '@/utils/apiRequest';
import { feedDictionary } from '@/services/modules/ecommerce/feed';
import FeedHeaderMapperLoader from '@/components/atoms/Loaders/FeedHeaderMapperLoader';

export default {
  name: 'FeedHeaderUrlInput',
  components: {
    FeedHeaderMapperLoader,
    FeedHeaderMapper,
    AsterixInput,
    FormRow,
  },
  props: {
    /** @type {Object}  key is the header from file, value is our header mapping */
    value: {
      type: Object,
      default: () => {},
    },
    showMapping: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      feedFile: new FeedFile(),
      fileHeaders: null,
      showHeaderMapping: false,
      mapLoading: false,
    };
  },
  created() {
    this.feedFile = this.value;
    this.showHeaderMapping = this.showMapping;
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),

    async onMapClick() {
      this.mapLoading = true;
      await apiRequest(async () => {
        const { data } = await feedDictionary({ url: this.feedFile.source });
        this.feedFile.dictionary = data.dictionary;
        this.showHeaderMapping = true;
      }).catch(() => {
        this.createToast(Toast.error('File not mapped', 'Check if the url is correct'));
      });
      this.mapLoading = false;
    },
    /**
     * Set vars to their initial value but the source
     */
    resetHeaderMapping() {
      this.feedFile.dictionary = {};

      this.fileHeaders = null;
      this.showHeaderMapping = false;
      this.emitValue();
    },
    /**
     * Set vars to their initial value
     */
    reset() {
      this.feedFile.source = null;
      this.resetHeaderMapping();
    },
    emitValue() {
      this.$emit('input', this.feedFile);
      this.$emit('change');
    },
  },
};
</script>

<style scoped></style>
