var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full" }, [
    !_vm.showHeaderMapping
      ? _c(
          "div",
          [
            _c(
              "form-row",
              [
                _c(
                  "sun-label-group",
                  {
                    staticClass: "w-full",
                    attrs: { text: "Choose your file" },
                  },
                  [
                    _c("sun-file-uploader", {
                      attrs: {
                        accept:
                          ".csv,text/csv,application/vnd.ms-excel,text/xml,application/xml",
                        required: "required",
                        "max-size": 104857600,
                      },
                      on: { change: _vm.onSourceFileChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "text-left" },
          [
            !_vm.isHeaderMappingLoading
              ? _c("feed-header-mapper", {
                  on: { change: _vm.emitValue },
                  model: {
                    value: _vm.feedFile.dictionary,
                    callback: function ($$v) {
                      _vm.$set(_vm.feedFile, "dictionary", $$v)
                    },
                    expression: "feedFile.dictionary",
                  },
                })
              : _c("feed-header-mapper-loader"),
            _c(
              "sun-button",
              {
                staticClass:
                  "custom-p-1 text-xs mt-5 bg-gray-700 opacity-50 hover:bg-gray-900",
                attrs: { variant: "pill", color: "gray" },
                on: { click: _vm.reset },
              },
              [_vm._v(" Cancel and upload another file ")]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }