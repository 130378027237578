<template functional>
  <div class="loading" :class="data.staticClass || ''"></div>
</template>

<script>
export default {
  name: 'BasicLoader',
};
</script>

<style scoped>
.loading {
  @apply bg-gray-300 animate-pulse;
}
</style>
