<template>
  <div class="w-full">
    <div v-if="!showHeaderMapping">
      <form-row
        ><sun-label-group text="Choose your file" class="w-full">
          <sun-file-uploader
            accept=".csv,text/csv,application/vnd.ms-excel,text/xml,application/xml"
            required="required"
            :max-size="104857600"
            @change="onSourceFileChange"
          /> </sun-label-group
      ></form-row>
    </div>
    <div v-else class="text-left">
      <feed-header-mapper v-if="!isHeaderMappingLoading" v-model="feedFile.dictionary" @change="emitValue" />
      <feed-header-mapper-loader v-else />

      <sun-button
        variant="pill"
        class="custom-p-1 text-xs mt-5 bg-gray-700 opacity-50 hover:bg-gray-900"
        color="gray"
        @click="reset"
      >
        Cancel and upload another file
      </sun-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import FeedHeaderMapper from '@/components/organisms/shared/FeedHeaderMapper';
import FormRow from '@/components/atoms/FormRow/FormRow';
import FeedFile from '@/model/shared/FeedFile';
import apiRequest from '@/utils/apiRequest';
import { feedDictionary } from '@/services/modules/ecommerce/feed';
import FeedHeaderMapperLoader from '@/components/atoms/Loaders/FeedHeaderMapperLoader';

export default {
  name: 'FeedHeaderFileInput',
  components: { FeedHeaderMapperLoader, FormRow, FeedHeaderMapper },
  props: {
    /** @type {Object}  key is the header from file, value is our header mapping */
    value: {
      type: Object,
      default: () => {},
    },
    required: {
      type: Boolean,
      default: () => true,
    },
    showMapping: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      feedFile: new FeedFile(),
      fileHeaders: null,
      showHeaderMapping: false,
      isHeaderMappingLoading: false,
    };
  },
  created() {
    this.feedFile = this.value;
    this.showHeaderMapping = this.showMapping;

    if (this.showHeaderMapping) {
      this.isHeaderMappingLoading = false;
    }
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async onSourceFileChange(files) {
      this.feedFile.source = files[0];
      this.emitValue();
      await this.getDictFromFile();
    },
    reset() {
      this.showHeaderMapping = false;
      this.fileHeaders = null;

      this.feedFile = new FeedFile();
      this.emitValue();
    },
    emitValue() {
      this.$emit('input', this.feedFile);
      this.$emit('change');
    },
    async getDictFromFile() {
      await apiRequest(async () => {
        this.isHeaderMappingLoading = true;
        this.showHeaderMapping = true;
        const { data } = await feedDictionary(this.feedFile.source);
        this.feedFile.dictionary = data.dictionary;
        this.isHeaderMappingLoading = false;
      }).catch(() => {
        this.isHeaderMappingLoading = false;
        this.createToast(Toast.error('Error parsing file!', 'Try again with another file, please...'));
        this.reset();
      });
    },
  },
};
</script>
