var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("form-row", [
        _c(
          "div",
          { staticClass: "flex w-full" },
          [
            _c(
              "sun-label-group",
              { staticClass: "w-full", attrs: { text: "Feed URL" } },
              [
                _c("asterix-input", {
                  staticClass: "w-full",
                  attrs: {
                    "class-input": "w-full",
                    type: "url",
                    placeholder: "https://www.example.com/my-custom-feed/",
                    required: "required",
                    "auto-validate": "",
                  },
                  on: { change: _vm.emitValue },
                  model: {
                    value: _vm.feedFile.source,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.feedFile,
                        "source",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "feedFile.source",
                  },
                }),
              ],
              1
            ),
            _c(
              "sun-button",
              {
                staticClass:
                  "custom-p-1 bg-gray-800 hover:bg-gray-900 text-xs ml-2 h-8 mt-8",
                attrs: {
                  variant: "pill",
                  color: "gray",
                  disabled: !_vm.feedFile.source,
                  loading: _vm.mapLoading,
                },
                on: { click: _vm.onMapClick },
              },
              [_vm._v("Map")]
            ),
          ],
          1
        ),
      ]),
      _vm.showHeaderMapping
        ? _c("form-row", [
            _c(
              "div",
              { staticClass: "w-full flex-col text-left mt-4" },
              [
                !_vm.mapLoading
                  ? _c("feed-header-mapper", {
                      on: { change: _vm.emitValue },
                      model: {
                        value: _vm.feedFile.dictionary,
                        callback: function ($$v) {
                          _vm.$set(_vm.feedFile, "dictionary", $$v)
                        },
                        expression: "feedFile.dictionary",
                      },
                    })
                  : _c("feed-header-mapper-loader"),
                _c(
                  "sun-button",
                  {
                    staticClass: "custom-p-1 text-xs mt-4",
                    attrs: { variant: "pill", color: "gray" },
                    on: { click: _vm.reset },
                  },
                  [_vm._v("Reset")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }