var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: { closable: "", "modal-class": "asterix-modal-size h-full" },
    on: { submit: _vm.onSubmit, cancel: _vm.closeModal },
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function () {
            return [_vm._t("title")]
          },
          proxy: true,
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "form-row",
                [
                  _c(
                    "sun-label-group",
                    { staticClass: "w-full", attrs: { text: "Title" } },
                    [
                      _c("asterix-input", {
                        attrs: {
                          id: "name",
                          type: "text",
                          name: "name",
                          "validate-on-blur": "",
                          minlength: 3,
                          maxlength: 50,
                          placeholder: "My cool feed parser provider...",
                          required: "required",
                        },
                        model: {
                          value: _vm.feedParser.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.feedParser, "name", $$v)
                          },
                          expression: "feedParser.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "form-row",
                [
                  _c(
                    "sun-label-group",
                    { staticClass: "w-full", attrs: { text: "Add Utms" } },
                    [
                      _c("sun-text-area", {
                        attrs: {
                          value: _vm.feedParser.utm,
                          resize: "resize-y",
                        },
                        on: { change: _vm.utmChanges },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "form-row",
                [
                  _c("creative-feed-type-picker", {
                    attrs: { "is-editing": _vm.isEditing },
                    model: {
                      value: _vm.feedParser,
                      callback: function ($$v) {
                        _vm.feedParser = $$v
                      },
                      expression: "feedParser",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("asterix-footer-modal", {
                attrs: { loading: _vm.isLoading },
                on: { cancel: _vm.closeModal },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }