<template>
  <div class="flex align-middle flex-wrap">
    <save-button ref="submitBtn" class="w-full sm:w-auto" :loading="loading" @click="$emit('save')"></save-button>
    <sun-button
      variant="pill"
      class="w-full text-sm custom-p-1 mt-4 sm:w-auto sm:mt-0 sm:ml-4"
      color="white"
      @click="$emit('cancel')"
    >
      Cancel
    </sun-button>
  </div>
</template>

<script>
import SaveButton from '@/components/atoms/SaveButton';

export default {
  name: 'AsterixFooterModal',
  components: { SaveButton },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped></style>
