var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("form-row", {
        staticClass: "mt-4",
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
        ]),
      }),
      _c("form-row", {
        staticClass: "mt-4",
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
        ]),
      }),
      _c("form-row", {
        staticClass: "mt-4",
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
        ]),
      }),
      _c("form-row", {
        staticClass: "mt-4",
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
        ]),
      }),
      _c("form-row", {
        staticClass: "mt-4",
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [_c("line-loader", { staticClass: "h-8" })]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }